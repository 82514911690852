const { amgApi } = require("@/service/axios");

class ClientPrograms {
    async getClientPrograms(params) {
        try {
            const data = await amgApi.post("commons/client-programs/get-client-programs", params);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default new ClientPrograms();