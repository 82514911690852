<template>
  <div>
    <div class="d-flex justify-content-end mr-2 mt-2" style="gap: 10px">
      <b-button
        v-if="(isCeo || isSupervisor || isChief) && purgeCounter > 0"
        variant="danger"
        class="mr-50"
        @click="deleteAlertsToPurge"
      >
        <feather-icon icon="AlertOctagonIcon" class="mr-50" /> Purge Duplicate
        Alerts ({{ purgeCounter > 99 ? "99+" : purgeCounter }})
      </b-button>
      <b-button
        v-if="isCeo || isSupervisor || isChief"
        variant="warning"
        class="mr-50"
        @click="openModalClientsWithoutTask"
      >
        <feather-icon icon="UserMinusIcon" class="mr-50" /> Without Task
      </b-button>
      <b-button
        v-if="isCeo || isSupervisor || isChief"
        variant="warning"
        @click="openModalWaitingAlert"
      >
        <feather-icon icon="AlertTriangleIcon" class="mr-50" />
        Alert Pending
      </b-button>
    </div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="$refs.refClientsList.refresh()"
      @reset-all-filters="updateTable"
    >
      <template #buttons>
        <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendEmail"
        >
          <feather-icon icon="MailIcon" class="mr-50" />Send Email
        </b-button>
        <!-- Button Send To Connection -->
        <b-button
          variant="success"
          class="ml-1"
          :disabled="clients_connection.isEmpty()"
          :title="messageNotFound ? messageNotFound : ''"
          :style="
            messageNotFound
              ? 'background-color: red !important; border-color: red !important;'
              : ''
          "
          @click="openModalSendToConnection"
        >
          <feather-icon
            :icon="messageNotFound ? 'UserXIcon' : 'SendIcon'"
            class="mr-50"
          />Send To Connection
        </b-button>
        <!-- Button refresh -->
        <b-button
          v-if="isCeo || isSupervisor"
          variant="success"
          class="ml-1"
          :disabled="pnttime"
          @click="contador"
        >
          <feather-icon icon="RefreshCcwIcon" class="mr-50" />{{
            pnttime == false
              ? "Refresh"
              : tiempominutos + ":" + tiemposegundos + " sec"
          }}
        </b-button>
      </template>

      <template #custom-vselect>
        <FilterStatusAccount :filters="filters[13]" />
      </template>

      <template #table>
        <b-table
          slot="table"
          ref="refClientsList"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Selected Title -->
          <template #head(selected)>
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template>
          <!-- Selected -->
          <template v-slot:cell(selected)="data">
            <b-form-group>
              <b-form-checkbox
                v-model="clientsSms"
                :value="data.item"
                :name="data.item.account_id"
                class="mt-1"
              />
            </b-form-group>
          </template>

          <template #cell(date_activation)="data">
            <div
              style="text-align: center"
              class="text-bold"
              :class="
                data.item.date_activation != null ? 'text-success' : 'text-info'
              "
            >
              {{ data.item.date_activation ? "Activated" : "No" }}
              <br />
              {{ data.item.date_activation | myGlobalDay }}
            </div>
          </template>

          <!-- Column NAME -->
          <template #cell(lead_name)="data">
            <b-row>
              <div>
                <!-- <feather-icon
                    class="cursor-pointer"
                    v-b-tooltip.hover.top="
                      getRiskTooltip(data.item.risk_type)
                    "
                    :icon="getRiskIcon(data.item.risk_type)"
                    :class="getRiskClass(data.item.risk_type)"
                  /> -->

                <router-link
                  :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                  :to="{
                    name: 'ce-customer-service-dashboard',
                    params: {
                      idClient: data.item.account_id,
                    },
                  }"
                  target="_blank"
                >
                  {{ data.item.lead_name }}
                </router-link>
                <!-- <b-button
                  variant="outline"
                  class="py-0 pl-0"
                  :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                  v-else
                  @click="validateAccess(data.item)"
                >
                  {{ data.item.lead_name }}
                </b-button> -->
                <b-badge
                  pill
                  :variant="
                    data.item.language == 'ES' ? 'light-warning' : 'light-info'
                  "
                  class="cursor-info"
                  v-b-tooltip.hover.right="'Language'"
                >
                  {{ data.item.language }}
                </b-badge>
                <br />
                <span v-if="data.item.user_responsible" class="text-warning">
                  <tabler-icon
                    :icon="
                      data.item.user_responsible === currentUser.user_id
                        ? 'StarIcon'
                        : 'LockIcon'
                    "
                    size="15"
                  />
                  <strong>CEO's client</strong>
                </span>
                <div class="text-primary">
                  <span> {{ data.item.eeuustate }} </span>
                </div>
              </div>
            </b-row>
            <b-row>
              <badge-risky-clients
                :id-account="data.item.account_id"
                :risk-level="data.item.risk_level"
                :risk-description="data.item.risk_description"
                :risk-type="data.item.risk_type"
              />
            </b-row>
            <b-row>
              <span
                v-if="
                  data.item.client_type_id == 2 &&
                  data.item.migration == 'Accepted'
                "
              >
                <b-badge class="font10" variant="success">Migration</b-badge>
              </span>
            </b-row>
            <b-row style="margin-top: 5px">
              <b-badge v-if="data.item.has_court == 1" variant="info"
                >IN COURT</b-badge
              >
            </b-row>
          </template>

          <!-- account -->
          <template #cell(account)="data">
            <div>
              <div>{{ data.value }}</div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <status-account :account="data.item" />
                  <b-badge v-if="data.item.claims != 0" variant="light-info">
                    IN CLAIM ({{ data.item.claims }})
                  </b-badge>
                </li>
              </ul>
            </div>
          </template>
          <!-- COLUMN OTHER PROGRAMS -->
          <template #cell(qty_services)="{ item }">
            <ClientPrograms
              :clientData="item"
              :programName="item.program_name"
              :programId="item.program_id"
            />
          </template>
          <!-- Column MP -->
          <template
            v-if="
              (moduleId != 2 && moduleId != 6) ||
              (moduleId == 6 && currentUser.role_id != 4)
            "
            #cell(monthly_amount)="data"
          >
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  {{ data.item.monthly_amount | formatMoney }}
                </li>
              </ul>
            </div>
          </template>
          <!-- Column Paid -->
          <template #cell(state_lp)="data">
            <span
              :class="data.item.state_lp == 0 ? 'text-success' : 'text-danger'"
            >
              {{ data.item.state_lp == 0 ? "YES" : "NO" }}
            </span>
          </template>
          <!-- Column LP -->
          <template
            v-if="
              (moduleId != 2 && moduleId != 6) ||
              (moduleId == 6 && currentUser.role_id != 4)
            "
            #cell(last_payment)="data"
          >
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none; position: relative">
                  <span style="margin-left: 15px">
                    {{ data.value == "-" ? "" : data.value | myGlobal }}
                    <b-img
                      v-if="data.item.state_lp == 1"
                      :src="assetsImg + '/images/icons/lastpayment-out.ico'"
                      style="
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                      "
                    />
                    <b-img
                      v-else
                      :src="assetsImg + '/images/icons/lastpayment-in.ico'"
                      style="
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                      "
                    />
                  </span>
                </li>
              </ul>
            </div>
          </template>
          <!-- Column PT -->
          <template
            v-if="
              (moduleId != 2 && moduleId != 6) ||
              (moduleId == 6 && currentUser.role_id != 4)
            "
            #cell(status_payment)="data"
          >
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <status-payment :account="data.item" />
                </li>
              </ul>
            </div>
          </template>
          <!-- column SCORE -->
          <template #cell(score)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <b-badge
                    v-if="data.value"
                    style="width: 50px"
                    pill
                    :variant="
                      data.value <= 659 && !data.value
                        ? 'danger'
                        : data.value >= 660 && data.value <= 699
                        ? 'warning'
                        : data.value >= 700 && data.value <= 759
                        ? 'primary'
                        : data.value >= 760 && data.value <= 850
                        ? 'success'
                        : 'danger'
                    "
                  >
                    {{ data.value }}
                  </b-badge>
                </li>
              </ul>
            </div>
          </template>
          <!-- Column Phone Number -->
          <template #cell(mobile)="data">
            <div id="goals">
              {{ data.value }}
            </div>
          </template>
          <!-- column LN -->
          <template #cell(ln_date)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  {{ data.item.ln_date | myGlobal }}
                </li>
              </ul>
            </div>
          </template>
          <!-- column UT -->
          <template #cell(enrol_date)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  {{ data.item.created_at | myGlobal }}
                </li>
              </ul>
            </div>
          </template>
          <!-- Column ADVISOR -->
          <template #cell(state_advisor)="data">
            <div>
              <ul style="padding-left: 0px; margin-bottom: 0px">
                <li style="list-style: none">
                  <div>
                    <feather-icon
                      id="icon"
                      :title="
                        data.value == 1
                          ? 'Active'
                          : data.value == 2
                          ? 'Busy'
                          : data.value == 3
                          ? 'Away'
                          : 'Offline'
                      "
                      icon="CircleIcon"
                      size="13"
                      :style="
                        data.value == 1
                          ? 'background:green'
                          : data.value == 2
                          ? 'background:orange'
                          : data.value == 3
                          ? 'background:red'
                          : 'background:#ccc'
                      "
                    />
                    {{ data.item.advisor_name }}
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <!--          Column Access Credential-->
          <template #cell(access_cred)="data">
            <span
              class="cursor-pointer"
              @click="
                openModalListCredentials(
                  data.item.account_id,
                  data,
                  item.id,
                  data.item.lead_name
                )
              "
              >AC</span
            >
          </template>
          <template #cell(migration)="data">
            <div
              v-if="data.value !== 'Accepted'"
              class="text-center"
              :class="data.value == 'Pending' ? 'text-primary' : 'text-danger'"
            >
              {{ data.value }}
            </div>
          </template>
          <!-- Column SERVICES -->
          <template
            v-if="
              currentUser.role_id == 1 ||
              currentUser.role_id == 2 ||
              currentUser.role_id == 3 ||
              currentUser.role_id == 15
            "
            #cell(service)="data"
          >
            <actions-table
              :options="serviceOptions"
              :row-data="data.item"
              :canDerivate="true"
              :fromCeDigital="true"
              @onRowDelete="onRowDelete"
              @onRowEdit="onRowEdit"
              @onRowProcess="onRowProcess"
              @modalChangeService="modalChangeService"
              @modalAddService="modalAddService"
            />
          </template>
          <!-- Column SERVICES -->
          <template
            v-if="
              currentUser.role_id == 1 ||
              currentUser.role_id == 2 ||
              currentUser.role_id == 3 ||
              currentUser.role_id == 4 ||
              currentUser.role_id == 15 ||
              currentUser.role_id == 16 ||
              currentUser.role_id == 17
            "
            #cell(actions)="data"
          >
            <b-dropdown
              v-if="data.item.user_responsible != 1"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- Add Service -->
              <b-dropdown-item
                variant="warning"
                @click="modalAddService(data.item)"
              >
                <feather-icon icon="FilePlusIcon" />
                <span class="align-middle ml-50">Add service</span>
              </b-dropdown-item>
              <!-- Change Service -->

              <b-dropdown-item
                :variant="
                  $route.matched[0].meta.module == 26 ? 'warning' : 'success'
                "
                @click="modalChangeService(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Change service</span>
              </b-dropdown-item>
              <!-- SendToSales -->
              <!-- <b-dropdown-item
                v-if="
                  data.item.is_in_sale_process === 0 &&
                  $route.matched[0].meta.module === 22
                "
                v-b-tooltip.hover.left="'Send to Sales'"
                v-b-modal.modal-primary
                @click="openDerivationActionModal(data.item, data.index)"
              >
                <feather-icon icon="CornerUpLeftIcon" />
                <span class="align-middle ml-50">Send to Sales</span>
              </b-dropdown-item> -->

              <!-- SalesProcessTracking -->
              <b-dropdown-item
                v-if="
                  $route.matched[0].meta.module === 22 &&
                  data.item.is_derivated === 1
                "
                v-b-tooltip.hover.left="'Sales process tracking'"
                v-b-modal.modal-primary
                variant="success"
                @click="openDerivationTrackingModal(data.item)"
              >
                <feather-icon icon="DollarSignIcon" />
                <span class="align-middle ml-50">Sales process tracking</span>
              </b-dropdown-item>
              <!-- sendSMS -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'Send SMS'"
                v-b-modal.modal-primary
                @click="modalSmsOpen(data.item.account_id, data.item.lead_name)"
              >
                <feather-icon icon="MessageSquareIcon" />
                <span class="align-middle ml-50">SMS</span>
              </b-dropdown-item>
              <!-- SendEmail -->
              <b-dropdown-item
                v-b-tooltip.hover.left="'Send Email'"
                v-b-modal.modal-primary
                @click="
                  modalSendEmailOpen(data.item.account_id, data.item.lead_name)
                "
              >
                <feather-icon icon="MailIcon" />
                <span class="align-middle ml-50">Send Email</span>
              </b-dropdown-item>
              <!-- Migrate client to Regular -->
              <b-dropdown-item
                v-if="data.item.migration !== 'Pending'"
                v-b-tooltip.hover.left="'Migrate to Regular'"
                v-b-modal.modal-primary
                @click="
                  openRequestMigrationModal({
                    id: data.item.account_id,
                    client_name: data.item.lead_name,
                    account: data.item.account,
                  })
                "
              >
                <feather-icon icon="ShuffleIcon" />
                <span class="align-middle ml-50">Migrate to Regular</span>
              </b-dropdown-item>
              <!-- SendToConnection -->
              <b-dropdown-item
                v-if="
                  (currentUser.role_id == 1 ||
                    currentUser.role_id == 2 ||
                    currentUser.role_id == 17 ||
                    currentUser.role_id == 15) &&
                  (data.item.status == 2 || data.item.status == 5) &&
                  (data.item.in_connection == 'ORIGIN' ||
                    (data.item.in_connection == 'ADMINISTRATION' &&
                      data.item.status_connection == 'REJECTED'))
                "
                v-b-tooltip.hover.left="'Send To Connection'"
                v-b-modal.modal-primary
                @click="openModalSendClientToConnection(data.item)"
              >
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send To Connection</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #cell(tracking)="data">
            <div class="pt-1">
              <feather-icon
                icon="MenuIcon"
                class="cursor-pointer"
                size="15"
                @click="
                  openTrackingMigrationModal({
                    id: data.item.account_id,
                    client_name: data.item.lead_name,
                    account: data.item.account,
                  })
                "
              />
            </div>
          </template>

          <template #cell(task)="data">
            <div>
              <b-badge
                @click="
                  taskInfo(data.item).tag == 'no'
                    ? openCustomerTracking(data.item.account_id)
                    : ''
                "
                :variant="taskInfo(data.item).variant"
                class="cursor-pointer"
              >
                {{ taskInfo(data.item).text }}
              </b-badge>
            </div>
            <div>
              {{ data.item.task | myGlobalDay }}
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <!-- Modal send sms -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="name_clients_arr[0]"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal call rounds -->
    <modal-call-rounds
      v-if="modalCall"
      :modal-call-rounds="modalCall"
      :name-leads="clientsSms"
      :type-call="0"
      @updateTable="updateTable"
      @closeModal="closeModalCallRounds"
    />
    <!-- Modal Send Email -->
    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="name_clients_arr"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <modal-send-client-to-connection
      v-if="showModalSendToConnection"
      :modal-client-to-connection="showModalSendToConnection"
      :name-client="name_clients_arr"
      :destiny="'CONNECTION'"
      @onClose="closeModalSendToConnection"
      @onSubmit="sendToConnection($event)"
    />
    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />
    <list-access-credentials-modal
      v-if="modalListCredentialsOn"
      :credential-id="credentialId"
      :account-number="accountNumber"
      :client-name="clientNameToPass"
      @close="closeModalListCredentials"
    />
    <tracking-migration-modal
      v-if="isTrackingMigrationModalOpened"
      :current-client="currentClient"
      @close="closeTrackingMigrationModal"
    />
    <request-migration-modal
      v-if="isRequestMigrationModalOpen"
      :current-client="currentClient"
      :new-client-type-id="setNewClientTypeId"
      :is-migration-request="true"
      @close="closeRequestMigrationModal()"
      @update="updateTable()"
    />
    <!-- Component Commons -->
    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="saleClientP"
      :programs-all="programsAll"
      :users-services="usersServices"
      :header-s="headerS"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    />
    <!-- derivation action -->
    <derivation-action-modal
      v-if="derivationActionModal"
      :derivation-data="derivationData"
      @send="updateRow"
      @closeModal="closeDerivationActionModal"
    />
    <!--    derivation tracking modal-->
    <derivation-tracking-modal
      v-if="derivationTrackingModal"
      :derivation-data="derivationData"
      @closeModal="closeDerivationTrackingModal"
    />
    <modal-validate-access
      v-if="modalStateView"
      :client-data="clientData"
      @closeModal="modalStateView = false"
    />
    <clients-without-task
      v-if="modalClientsWithoutTask"
      @close="modalClientsWithoutTask = false"
    />
    <waiting-alert-list
      v-if="modalClientsWaitingAlert"
      @close="modalClientsWaitingAlert = false"
    />
    <add-customer-tracking
      v-if="modalCustomerTracking"
      @close="closeCustomerTracking"
      :idClient="idClient"
    />
  </div>
</template>

<script>
// Table clients
import FilterSlot from "@/views/credit-experts/views/clients/components/clients/FilterSlot.vue";
import fields from "@/views/ce-digital/sub-modules/customer-service/views/clients-table/data/fields.clients.data";
import TrackingMigrationModal from "@/views/ce-digital/sub-modules/customer-service/views/clients-table/components/modals/TrackingMigrationModal.vue";
import WaitingAlertList from "@/views/ce-digital/sub-modules/customer-service/views/clients-table/components/modals/alerts-pending/WaitingAlertList.vue";
// service
// import ClientService from '@/views/boost-credit/services/clients.service'

import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";
import ModalCallRounds from "@/views/boost-credit/views/clients/components/clients/modals/SendToCallRounds.vue";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms.vue";
import ModalValidateAccess from "@/views/commons/components/search-digital/modal/ModalValidateAccess.vue";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import ActionsTable from "@/views/ce-digital/components/ActionsTable.vue";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import FiltersClientsData from "@/views/ce-digital/sub-modules/customer-service/views/clients-table/data/filters.clients.data.js";
import ListAccessCredentialsModal from "@/views/boost-credit/views/clients/components/clients/modals/ListAccessCredentialsModal.vue";
import RequestMigrationModal from "@/views/ce-digital/sub-modules/settings/views/migrations/modal/RequestModal.vue";
import ModalSendClientToConnection from "@/views/credit-experts/views/clients/components/clients/modals/ModalSendClientToConnection.vue";
import { mapGetters, mapActions } from "vuex";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import DerivationActionModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationActionModal.vue";
import DerivationTrackingModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationTrackingModal.vue";
import SearchDiditalCE from "@/views/commons/components/search-digital/services/search-digital.js";
import ClientsWithoutTask from "@/views/ce-digital/sub-modules/customer-service/views/clients-table/components/modals/ClientsWithoutTask.vue";
import DigitalClientService from "@/views/ce-digital/sub-modules/customer-service/views/clients-table/services/digital-client.service.js";
import AddCustomerTracking from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/modals/AddCustomerTracking.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import ClientPrograms from "@/views/commons/components/client-programs/ClientPrograms.vue";
import { modifyVisibility } from "@/helpers/fields-table";
export default {
  components: {
    DerivationActionModal,
    FilterSlot,
    // modals
    BadgeRiskyClients,
    BusinessModal,
    CreditExpertsModal,
    ListAccessCredentialsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    ModalCallRounds,
    ModalAddService,
    ModalSendEmail,
    ModalSendSms,
    StatusPayment,
    StatusAccount,
    ActionsTable,
    ModalSendClientToConnection,
    TrackingMigrationModal,
    RequestMigrationModal,
    DerivationTrackingModal,
    ModalValidateAccess,
    ClientsWithoutTask,
    WaitingAlertList,
    AddCustomerTracking,
    NcrLeadsService,
    FilterStatusAccount,
    ClientPrograms,
  },
  data() {
    return {
      clientDataToRiskyBadge: {},
      currentClient: {},
      headerS: {},
      goals: [],
      clientNameToPass: "",
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      modal: {
        programs: false,
      },
      modalCall: false,
      modalOpenAddService: false,
      typeModal: null,
      clients: null,
      clientsSms: [],
      selectAll: false, // total
      name_clients_arr: [],
      messageNotFound: "",
      allClients: [], // lista de listas
      arrayCurrentPage: [],
      filters: FiltersClientsData,
      fields,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      totalAmount: 0,
      sortBy: "",
      sortDesc: true,
      startPage: null,
      endPage: "",
      nextPage: "",
      toPage: null,
      isBusy: false,
      isTrackingMigrationModalOpened: false,
      isRequestMigrationModalOpen: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Name or phone number...",
        model: "",
      },
      tiemposegundos: 30,
      tiempominutos: 0,
      time1: true,
      pnttime: false,
      advisor: "",
      modalSendSms: false,
      modalSendEmail: false,
      serviceOptions: [],
      actionOptions: [],
      detailsClient: {},
      saleClientP: {},
      programsAll: [],
      usersServices: [],
      items: [],
      event: null,
      modalListCredentialsOn: false,
      credentialId: "",
      accountNumber: "",
      showModalSendToConnection: false,
      derivationActionModal: false,
      derivationData: null,
      derivationIndex: null,
      derivationTrackingModal: false,
      typeAddChange: null,
      modalStateView: false,
      clientData: {},
      modalClientsWithoutTask: false,
      modalClientsWaitingAlert: false,
      purgeCounter: 0,
      modalCustomerTracking: false,
      idClient: null,
    };
  },
  async created() {
    await this.usersAllPrograms();
    await this.getCountAlertsToPurge();
    await this.getAllStatusClientAccount();
    this.filters[0].model = true;
    this.filters[1].model = false;
    this.filters[2].model = false;
  },
  async mounted() {
    if (this.currentUser.role_id == 3) {
      modifyVisibility(this.fields, "task", false);
      modifyVisibility(this.fields, "status_payment", false);
    }
    if (this.moduleId == 22) {
      this.filters[0].visible = false;
      this.filters[1].visible = false;
      this.filters[2].visible = false;
    }
    this.serviceOptions.push("addService");
    this.serviceOptions.push("changeService");
    this.actionOptions.push("sendToCallReturns");
    this.actionOptions.push("sendSMS");
    this.actionOptions.push("sendEmail");
  },
  methods: {
    getRiskTooltip(risk_type) {
      if (risk_type == "non-risky") {
        return "Non Risky";
      } else if (risk_type == "risky") {
        return "Risky";
      } else {
        return "Undefined Risk";
      }
    },
    getRiskIcon(risk_type) {
      if (risk_type == "non-risky") {
        return "CheckCircleIcon";
      } else if (risk_type == "risky") {
        return "XCircleIcon";
      } else {
        return "AlertCircleIcon";
      }
    },
    getRiskClass(risk_type) {
      if (risk_type == "non-risky") {
        return "text-success";
      } else if (risk_type == "risky") {
        return "text-danger";
      } else {
        return "text-info";
      }
    },
    updateTable() {
      this.filters[0].model = true;
      this.filters[1].model = false;
      this.filters[2].model = false;
      this.filters[3].model = null;
      this.filters[4].model = null;
      this.filters[5].model = null;
      this.filters[6].model = null;
      this.filters[7].model = null;
      this.filters[8].model = null;
      this.filters[9].model = null;
      this.filterPrincipal.model = "";
      this.$refs.refClientsList.refresh();
    },
    async getCountAlertsToPurge() {
      try {
        const { data } = await DigitalClientService.getCountAlertsToPurge();
        if (data) {
          const counter = data[0]?.counter;
          this.purgeCounter = counter;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteAlertsToPurge() {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          `Are you sure you want to delete this ${this.purgeCounter} duplicated alerts?`,
          "You won't be able to revert this!",
          "warning"
        );
        if (!isConfirmed) return;
        this.addPreloader();
        await DigitalClientService.deleteAlertsToPurge();
        this.getCountAlertsToPurge();
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    // actions
    async modalToCallOpen(id, name, account, nameAccount, nameProgram) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.clientsSms = [
          {
            id,
            name,
            account,
            nameAccount,
            nameProgram,
          },
        ];
        this.modalCall = true;
      }
    },
    openDerivationActionModal(item, index) {
      this.derivationData = item;
      this.derivationIndex = index;
      this.derivationActionModal = true;
    },
    closeDerivationActionModal() {
      this.derivationActionModal = false;
    },
    updateRow() {
      this.$set(this.items[this.derivationIndex], "is_derivated", 1);
      this.$set(this.items[this.derivationIndex], "is_in_sale_process", 1);
    },
    openDerivationTrackingModal(item) {
      this.derivationData = item;
      this.derivationTrackingModal = true;
    },
    closeDerivationTrackingModal() {
      this.derivationTrackingModal = false;
    },
    openModalListCredentials(id, accNumber, clientName) {
      this.modalListCredentialsOn = true;
      this.credentialId = id;
      this.accountNumber = accNumber;
      this.clientNameToPass = clientName;
    },
    closeModalListCredentials() {
      this.modalListCredentialsOn = false;
    },
    async modalSendEmailOpen(id, name) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.name_clients_arr = [{ id, name }];
        this.modalSendEmail = true;
      }
    },
    async modalSmsOpen(id, name) {
      const result = await this.showConfirmSwal("Are you sure?");
      if (result.value) {
        this.name_clients_arr = [{ id, name }];
        this.modalSendSms = true;
      }
    },
    openModalCallRounds() {
      this.modalCall = true;
    },
    openModalSendToConnection() {
      const namecl = [];
      this.clients_connection.map((dato) => {
        namecl.push({
          name: dato.lead_name,
          id: dato.account_id,
          account: dato.account,
          status: dato.status,
          client_id: dato.client_id,
        });
      });
      this.name_clients_arr = namecl;
      this.showModalSendToConnection = true;
    },
    openModalSendClientToConnection(client) {
      const namecl = [];
      namecl.push({
        name: client.lead_name,
        id: client.account_id,
        account: client.account,
        status: client.status,
        client_id: client.client_id,
      });
      this.name_clients_arr = namecl;
      this.showModalSendToConnection = true;
    },
    async sendToConnection(clients, comment) {
      this.addPreloader();

      const params = {
        client_id: "",
        client_account_id: "",
        in_connection: "CONNECTION",
        status_connection: "",
        reason_connection: "",
        advisor_id: "",
        comment,
        user_id: this.currentUser.user_id,
      };

      const errors = [];

      clients.forEach(async (item) => {
        // item.status == 2 ? "ADMINISTRATION" : "CONNECTION";
        params.status_connection = item.status == 2 ? "HOLD" : "LOYAL";
        params.client_id = item.client_id;
        params.client_account_id = item.id;

        try {
          response = await ClientService.passClientToConnection(params);
        } catch (error) {
          errors.push({ error, params });
        }
      });
      this.removePreloader();

      if (!errors.isEmpty()) {
        this.showErrorSwal("Some clients was not send to connection");
        console.error(errors);
        return;
      }

      await this.$swal({
        title: "Save Succesfully",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Continue",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });

      this.selectAll = [];
      this.$refs.refClientsList.refresh();
      this.showModalSendToConnection = false;
    },
    closeModalSendToConnection() {
      this.showModalSendToConnection = false;
    },
    // openModalSendSms() {
    //   const namecl = [];
    //   this.clientsSms.map((dato) => {
    //     namecl.push({ name: dato.lead_name, id: dato.account_id });
    //   });
    //   this.name_clients_arr = namecl;
    //   this.modalSendSms = true;
    // },
    openModalSendEmail() {
      const namecl = [];
      this.clientsSms.map((dato) => {
        namecl.push({ name: dato.lead_name, id: dato.account_id });
      });
      this.name_clients_arr = namecl;
      this.modalSendEmail = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    closeModalCallRounds() {
      this.modalCall = false;
    },
    changeTypePayment(type) {
      if (type == "paymentType") {
        const { filOne, filTwo } =
          this.currentUser.role_id != 4
            ? { filOne: 4, filTwo: 5 }
            : { filOne: 3, filTwo: 4 };
        if (this.filters[filOne].model == 1) {
          this.filters[filTwo].visible = true;
        } else {
          this.filters[filTwo].model = null;
          this.filters[filTwo].visible = false;
        }
      }
    },
    async contador() {
      try {
        this.pnttime = true;
        this.updateReloj();
        await ClientService.generateRefresh();
      } catch (error) {
        console.log(error);
      }
    },
    updateReloj() {
      this.tiemposegundos = this.tiemposegundos;
      this.tiempominutos = this.tiempominutos;
      if (this.tiempominutos == 0 && this.tiemposegundos == 0) {
        this.$refs.refClientsList.refresh();
        this.pnttime = false;
        this.tiemposegundos = 59;
        this.tiempominutos = 1;
      } else {
        // Restamos un segundo al tiempo restante
        if (this.tiemposegundos == 0) {
          this.tiemposegundos = 59;
          if (this.tiempominutos != 0) {
            this.tiempominutos -= 1;
          }
        }
        this.tiemposegundos -= 1;
        // Ejecutamos nuevamente la función al pasar 1000 milisegundos (1 segundo)
        setTimeout(() => {
          this.updateReloj();
        }, 1000);
      }
    },
    selectedAll() {
      if (this.selectAll) {
        var namecl = [];
        this.items.map((dato) => {
          namecl.push(dato);
        });
        this.clientsSms = namecl;
      } else {
        this.clientsSms = [];
      }
    },
    async myProvider(ctx) {
      let sortBy = 17;
      let sortDirection = ctx.sortDesc ? "Desc" : "Asc";
      this.selectAll = false;
      if (ctx.sortBy === "lead_name") {
        sortBy = 5;
      } else if (ctx.sortBy === "account") {
        sortBy = 6;
      } else if (ctx.sortBy === "task") {
        sortBy = 43;
      } else if (ctx.sortBy === "date_activation") {
        sortBy = 10;
      } else if (ctx.sortBy === "state_lp") {
        sortBy = 35;
      } else if (ctx.sortBy === "monthly_amount") {
        sortBy = 12;
      } else if (ctx.sortBy === "last_payment") {
        sortBy = 26;
      } else if (ctx.sortBy === "status_payment") {
        sortBy = 34;
      } else if (ctx.sortBy === "ln_date") {
        sortBy = 46;
      } else if (ctx.sortBy === "enrol_date") {
        sortBy = 18;
      } else if (ctx.sortBy === "migration") {
        sortBy = 38;
      }
      try {
        const program = 3;
        const params = {
          per_page: ctx.perPage,
          text: this.filterPrincipal.model,
          from: this.filters[3].model,
          to: this.filters[4].model,
          program,
          orderby: sortBy,
          order: sortDirection,
          status: this.filters[13].model,
          type: this.filters[5].model,
          day: this.paymentDay,
          score: this.filters[7].model,
          rol_id: this.currentUser.role_id,
          status_search: 0,
          is_digital: 2,
          ismigrated: this.filters[8].model,
          hasApp: this.filters[9].model,
          usa_state: this.filters.find((e) => e.label == "USA state").model,
          language: this.filters[11].model,
          risky: this.filters[12].model,
          page: ctx.currentPage,
        };
        const data = await DigitalClientService.getSearchClients(params);

        this.items = data.data;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;

        return this.items || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async searchPrograms(lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          const programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          programs.sort((a, b) => a.program_id - b.program_id);
          for (let i = 0; i < this.programsAll.length; i++) {
            for (let y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          const sper = this.currentUser.sale_permission;
          const spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async usersAllPrograms() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
        this.usersServices = data.data; // .filter(item => item.id != 1);
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
    onRowDelete(id) {},
    async onRowEdit(data) {},
    async modalChangeService(data) {
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },
    onRowProcess(id) {},
    async modalAddService(data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        this.modalGeneralService(data, 3);
        await this.createEvent(data);
      }
    },
    async modalSendSMS(data) {
      const result = await this.showConfirmSwal("Are sure want to send SMS?");
      if (result.value) {
        this.modalGeneralService(data, 2);
      }
    },
    async modalSendEmailMenu(data) {
      const result = await this.showConfirmSwal("Are sure want to send Email?");
      if (result.value) {
        this.modalGeneralService(data, 2);
      }
    },
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.detailsClient.accounts = JSON.stringify([
        {
          program: this.detailsClient.program_name,
          account_id: this.detailsClient.account_id,
        },
      ]);
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    async openModalProgram(data) {
      this.removePreloader();
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: JSON.parse(this.detailsClient.accounts)[0].account_id,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.moduleId,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            const result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({ name: "sales-made-connection-client" })
                .catch((err) => {
                  console.log(err);
                });
            }
          } catch (error) {
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        // await this.updateRow();
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }

      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    openTrackingMigrationModal(id) {
      this.isTrackingMigrationModalOpened = true;
      this.currentClient = { ...id };
    },
    closeTrackingMigrationModal() {
      this.isTrackingMigrationModalOpened = false;
    },
    openRequestMigrationModal(id) {
      this.currentClient = { ...id };
      this.isRequestMigrationModalOpen = true;
    },
    closeRequestMigrationModal() {
      this.isRequestMigrationModalOpen = false;
    },
    async validateAccess(item) {
      this.modalStateView = true;
      let result = {
        lead_name: item.lead_name,
        client_id: item.client_id,
        client_account_id: item.account_id,
        typeAccess: await this.inCallRounds(item),
        wayAccess: 1,
        hasAccess: true,
      };
      this.clientData = result;
    },
    async inCallRounds(item) {
      if (item.motive_type != 1) {
        const { data, status } = await SearchDiditalCE.getClientCallRound({
          client_account_id: item.account_id,
        });
        if (status == 200) {
          if (data.length) {
            return data[0].motive_id;
          } else {
            return 2;
          }
        }
      } else {
        return item.motive_type;
      }
    },
    taskInfo(data) {
      if (data.call_round) {
        return {
          variant: "warning",
          text: "In Call Round",
          tag: "yes",
        };
      } else if (data.task && !data.call_round) {
        return {
          variant: "success",
          text: "In Task",
          tag: "yes",
        };
      } else {
        return {
          variant: "danger",
          text: "Without Task",
          tag: "no",
        };
      }
    },
    openCustomerTracking(idClient) {
      this.idClient = idClient;
      this.modalCustomerTracking = true;
    },
    closeCustomerTracking(update = false) {
      if (update) {
        this.items = this.items.map((item) => {
          if (item.account_id == this.idClient && item.hour) {
            let hour = item.hour;
            item.task = new Date().toLocaleDateString() + " " + hour;
          }
          return item;
        });
      }
      this.idClient = null;
      this.modalCustomerTracking = false;
    },
    openModalClientsWithoutTask() {
      this.modalClientsWithoutTask = true;
    },
    openModalWaitingAlert() {
      this.modalClientsWaitingAlert = true;
    },
    async getAllStatusClientAccount() {
      this.filters[13].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount(/* {
        parent_id: 1,
      } */);
      this.filters[13].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filters[13].options.push(...data);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    lengthClients() {
      return this.clientsSms.length == 0;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    clients_connection() {
      const array_to_connection = this.clientsSms.filter((item) =>
        [1, 2, 15, 17].some((e) => e == this.currentUser.role_id) &&
        [2, 5].some((f) => f == item.status) &&
        item.in_connection == "ORIGIN"
          ? true
          : item.in_connection == "ADMINISTRATION" &&
            item.status_connection == "REJECTED"
      );

      this.messageNotFound =
        array_to_connection.isEmpty() && this.selectAll
          ? "There are no clients to send"
          : "";

      return array_to_connection;
    },
    paymentType: {
      get() {
        return this.filters[6].model;
      },
      set(value) {
        this.filters[6].model = value;
      },
    },
    filterGeneral: {
      get() {
        return this.filters[0].model;
      },
      set(value) {
        this.filters[0].model = value;
      },
    },
    filterLoyal: {
      get() {
        return this.filters[1].model;
      },
      set(value) {
        this.filters[1].model = value;
      },
    },
    filterHold: {
      get() {
        return this.filters[2].model;
      },
      set(value) {
        this.filters[2].model = value;
      },
    },
    paymentDay: {
      get() {
        return this.filters[6].model;
      },
      set(value) {
        // this.filters[5].visible = value;
      },
    },
    setNewClientTypeId() {
      if (this.moduleId === 6) {
        return 2;
      }
      return 1;
    },
  },
  watch: {
    paymentType(newVal) {
      if (newVal == 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
      }
    },
    filterGeneral(newVal) {
      if (newVal) {
        this.filterLoyal = false;
        this.filterHold = false;
        this.filters[3].placeholder = "Date";
        this.filters[4].placeholder = "Date";
      }
    },
    filterLoyal(newVal) {
      if (newVal) {
        this.filterGeneral = false;
        this.filterHold = false;
        this.filters[3].placeholder = "Date (Loyal Income)";
        this.filters[4].placeholder = "Date (Loyal Income)";
      }
    },
    filterHold(newVal) {
      if (newVal) {
        this.filterGeneral = false;
        this.filterLoyal = false;
        this.filters[3].placeholder = "Date (Hold Income)";
        this.filters[4].placeholder = "Date (Hold Income)";
      }
    },
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}

.font10 {
  font-size: 10px;
}
</style>
