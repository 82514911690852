export default [
  {
    key: "lead_name",
    label: "Client",
    visible: true,
  },
  {
    key: "full_name",
    label: "Agent",
    visible: true,
  },
  {
    key: "language",
    label: "Language",
    thStyle: { textAlign: "center" },
    class: "text-center",
    visible: true,
  },
  {
    key: "date_event",
    label: "Alert Date",
    thStyle: { textAlign: "center" },
    visible: true,
  },
  {
    key: "alert_status",
    label: "Status",
    thStyle: { textAlign: "center" },
    visible: true,
  },
  {
    key: "alert_type",
    label: "Type",
    thStyle: { textAlign: "center" },
    visible: true,
  },
  {
    key: "created_at",
    label: "Created at",
    thStyle: { textAlign: "center" },
    visible: true,
  },
  {
    key: "actions",
    label: "Actions",
    tdStyle: { textAlign: "center" },
    visible: false,
  },
]
