import { amgApi } from "@/service/axios";

class CustomerService {
  async insertCustomerTracking(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/alert/insert-customer-tracking",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error insertCustomerTracking");
    }
  }
  async countAnalysisCr(params){
    try{
      const data = await amgApi.post('request-ncr/count-analysis-cr',params);
    return data;
    }catch(error){
      throw error;
    }
  }
}

export default new CustomerService();
