import { amgApi } from "@/service/axios";
class SearchDiditalCE {
    async searchDigital(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/search-digital", params);
            return data;
        } catch (error) {
            console.log("Error searchDigital");
        }
    }
    async validateAccessDashboard(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/validate-access-dashboard", params);
            return data;
        } catch (error) {
            console.log("Error validateAccessDashboard");
        }
    }
    async getExistentDocuments(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-existent-documents", params);
            return data;
        } catch (error) {
            console.log("Error getExistentDocuments");
        }
    }
    async getClientCallRound(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-client-callround", params);
            return data;
        } catch (error) {
            console.log("Error getClientCallRound");
        }
    }
}
export default new SearchDiditalCE();
