export default [
  {
    type: "button",
    margin: true,
    showButtonLabel: true,
    label: "General",
    variant: "primary",
    showIcon: true,
    icon: "UsersIcon",
    model: true,
    selectText: "text",
    value: 0,
    cols: 12,
    visible: true,
  },
  {
    type: "button",
    margin: true,
    showButtonLabel: true,
    label: "Loyal Client",
    variant: "primary",
    showIcon: true,
    icon: "StarIcon",
    model: false,
    selectText: "text",
    cols: 6,
    value: 1,
    visible: true,
  },
  {
    type: "button",
    margin: true,
    showButtonLabel: true,
    label: "Hold Client",
    variant: "primary",
    showIcon: true,
    value: 2,
    icon: "StarIcon",
    model: false,
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Payment Type",
    model: null,
    options: [
      { id: 0, text: "All" },
      { id: 1, text: "Automatic" },
      { id: 2, text: "Manual" },
      { id: 3, text: "Others" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Payment Day",
    model: null,
    options: [
      { value: 0, label: "All" },
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
      { value: 25, label: "25" },
      { value: 30, label: "30" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 6,
    visible: false,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Score",
    model: null,
    options: [
      { id: null, text: "All" },
      { id: 0, text: "0" },
      { id: 1, text: "300-400" },
      { id: 2, text: "401-500" },
      { id: 3, text: "500-600" },
      { id: 4, text: "600-650" },
      { id: 5, text: "651-700" },
      { id: 6, text: "701-750" },
      { id: 7, text: "751-800" },
      { id: 8, text: "801-850" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Migrate",
    model: null,
    options: [
      { id: null, text: "All" },
      { id: 1, text: "Native" },
      { id: 0, text: "Migrate" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Have an App",
    model: null,
    options: [
      { id: null, text: "All" },
      { id: 1, text: "Yes" },
      { id: 0, text: "No" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "USA state",
    model: null,
    options: [
      { id: null, text: "All" },
      { id: "CA", text: "California" },
      { id: "FL", text: "Florida" },
      { id: "TX", text: "Texas" },
      { id: "NM", text: "New Mexico" },
      { id: "AZ", text: "Arizona" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Language",
    model: null,
    options: [
      { text: "ES" },
      { text: "EN" },
    ],
    reduce: "text",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Risk",
    model: null,
    options: [
      { id: 1, text: "LOW" },
      { id: 2, text: "MEDIUM" },
      { id: 3, text: "HIGH" },
      { id: 4, text: "UNDEFINED" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 6,
    visible: true,
  },
  {
    type: "custom-select",
    margin: true,
    showLabel: true,
    label: "Client Status",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
  },
];
