import { amgApi } from "@/service/axios"

class CallRoundService {
    async getClientCallRoundsCE(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-clients-call-rounds-ce", params);
            return data;
        } catch (error) {
            console.log("Error getClientCallRoundsCE");
        }
    }
    async setTrackingCallRoundCE(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/set-tracking-call-rounds-ce", params);
            return data;
        } catch (error) {
            console.log("Error setTrackingCallRoundCE");
        }
    }
    async getTrackingCallRoundCE(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-tracking-call-rounds-ce", params);
            return data;
        } catch (error) {
            console.log("Error getTrackingCallRoundCE");
        }
    }
    async setNotAvailableCallRoundCE(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/set-not-available-call-rounds-ce", params);
            return data;
        } catch (error) {
            console.log("Error setNotAvailableCallRoundCE");
        }
    }
    async setAvailableCallRoundCE(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/set-available-call-rounds-ce", params);
            return data;
        } catch (error) {
            console.log("Error setAvailableCallRoundCE");
        }
    }


}

export default new CallRoundService()