<template>
    <div>
        <feather-icon
            icon="EyeIcon"
            :class="clientData.qty_services > 1 ? 'text-primary cursor-pointer' : 'text-secondary'"
            v-b-tooltip.hover.bottom="'Other services'"
            @click="clientData.qty_services > 1 && openClientProgramsModal()"
        />
        <b-modal v-model="showModal" hide-footer title="Other services">
            <programs-client-header
                :program="programName"
                :client="clientData.lead_name"
            />
            <b-table
                ref="clientProgramsTable"
                sticky-header="70vh"
                no-provider-filtering
                :items="myProvider"
                :fields="fields"
                show-empty
                responsive
            >
                <template #cell(program)="data">
                    <div>
                        <div style="max-height: 40px; max-width: 40px">
                            <img
                                :id="`image-${data.index}`"
                                :src="getProgramImageRoute(data.item.program_id)"
                                alt="img_services"
                                class="img-fluid"
                            />
                        </div>
                        <b-tooltip
                            triggers="hover"
                            :target="`image-${data.index}`"
                            :title="data.item.program"
                        />
                    </div>
                </template>
                <template #cell(enroll_date)="{item}">
                    <div>
                        {{ item.enroll_date | myGlobal }}
                    </div>
                </template>
                <template #cell(status_client)="{item}">
                    <status-account :account="item" />
                </template>
            </b-table>
        </b-modal>
    </div>
</template>
<script>
import Fields from "@/views/commons/components/client-programs/client-programs.fields.js";
import ClientProgramsServices from "@/views/commons/components/client-programs/client-programs.services.js"
import ProgramsClientHeader from "@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue"
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
    components: {
        ProgramsClientHeader,
        StatusAccount,
    },
    data() {
        return {
            showModal: false,
            fields: Fields,
        }
    },
    props: {
        clientData: {
            type: Object,
            required: true
        },
        programName: {
            type: String,
            required: true
        },
        programId: {
            type: Number,
            required: true
        }
    },
    methods: {
        async myProvider(){
            const params = {
                clientId: this.clientData.client_id,
                actualProgram: this.programId
            }
            try {
                this.addPreloader();
                const { data } = await ClientProgramsServices.getClientPrograms(params);
                return data;
            } catch (error) {
                this.showErrorSwal();
            } finally {
                this.removePreloader();
            }
        },
        openClientProgramsModal(){            
            this.showModal = true;
        }
    }
}
</script>