<template>
  <b-modal
    ref="modal-without-task"
    modal-class="modal-primary"
    title="Clients without task"
    title-tag="h3"
    size="lg"
    hide-footer
    @hidden="close"
  >
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="[]"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs.refClientsList.refresh()"
      @reset-all-filters="updateTable"
    >
      <b-table
        responsive
        slot="table"
        ref="refClientsList"
        sticky-header="50vh"
        :items="myProvider"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :fields="fields"
        :busy.sync="isBusy"
        small
        :style="['fontSize:5px', 'fontWeight:thin']"
      >
        <template #cell(client_name)="data">
          <router-link
            v-if="[1, 2, 14, 17].includes(currentUser.role_id)"
            :class="isDarkSkin ? 'text-warning' : 'text-primary'"
            style="font-weight: bold"
            :to="{
              name: 'ce-customer-service-dashboard',
              params: {
                idClient: data.item.account_id,
              },
            }"
            target="_blank"
          >
            {{ data.item.client_name }}
          </router-link>
          <div class="text-primary">
            {{ data.item.state_name }}
          </div>
        </template>
        <template #cell(mobile)="data">
          <span v-if="Boolean(data.item.mobile)">
            {{ data.item.mobile }}
          </span>
          <span v-else class="text-danger"> Mobile is needed </span>
        </template>
        <template #cell(last_contact)="data">
          {{ data.item.date_event | myGlobalDay }}
        </template>
        <template #cell(actions)="data">
          <feather-icon
            icon="Edit2Icon"
            size="15"
            class="text-warning cursor-pointer mr-1"
            :class="{ 'text-muted': !Boolean(data.item.mobile) }"
            @click="Boolean(data.item.mobile) && editClient(data.item)"
          />
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
      </b-table>
    </filter-slot>
    <add-customer-tracking
      v-if="modalCustomerTracking"
      @close="closeCustomerTracking"
      :idClient="idClient"
    />
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import ClientService from "@/views/commons/components/digital/services/clients.service.js";
import AddCustomerTracking from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/modals/AddCustomerTracking.vue";
export default {
  components: {
    AddCustomerTracking,
  },
  data() {
    return {
      isBusy: false,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPage: 0,
      toPage: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Name or phone number...",
        model: "",
      },
      fields: [
        {
          key: "client_name",
          label: "Clients",
          visible: true,
        },
        {
          key: "account",
          label: "Account",
          visible: true,
        },
        {
          key: "mobile",
          label: "Phone Number",
          visible: true,
        },
        {
          key: "last_contact",
          label: "Last Contact",
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          visible: true,
          class: "text-center",
        },
      ],
      items: [],
      modalCustomerTracking: false,
      idClient: null,
    };
  },
  mounted() {
    this.toggleModal("modal-without-task");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async myProvider(ctx) {
      console.log(ctx);
      let params = {
        page: ctx.currentPage,
        perPage: ctx.perPage,
        text: this.filterPrincipal.model,
      };
      const data = await ClientService.getClientsWoAlert(params);
      let items = data.data;
      this.startPage = data.from
      this.paginate.currentPage = data.current_page
      this.paginate.perPage = data.per_page
      this.toPage = data.to
      this.totalRows = data.total
      return items || [];
    },
    close() {
      this.$emit("close");
    },
    updateTable() {
      this.filterPrincipal.model = "";
      this.$refs.refClientsList.refresh();
    },
    editClient(item) {
      this.idClient = item.account_id;
      this.modalCustomerTracking = true;
    },
    closeCustomerTracking() {
      this.modalCustomerTracking = false;
      this.$refs.refClientsList.refresh();
    },
  },
  created() {},
};
</script>
<style></style>
