<template>
  <b-modal
    ref="validate-access-modal"
    title="VALIDATE ACCESS"
    size="xsm"
    modal-class="modal-primary "
    title-tag="h3"
    :no-close-on-backdrop="true"
    @hidden="closeModal"
  >
    <b-container>
      <b-row>
        <b-col class="flex-row">
          <h4 class="ml-1 pt-1">CLIENT</h4>
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py-1 w-75"
          >
            {{ clientData.lead_name }}
          </p>
        </b-col>
      </b-row>
      <validation-observer ref="formValiateAccess">
        <validation-provider v-slot="{ errors }" name="dob" rules="required">
          <div class="flex-row">
            <h6 class="pt-1 w-50">DOB</h6>
            <div class="widthInput1">
              <kendo-datepicker
                v-model="client.dob"
                v-mask="'##/##/####'"
                :style="
                  isDarkSkin ? 'border-color: rgba(255, 255, 255, 0.4)' : ''
                "
                :class="[
                  'w-100',
                  { 'text-white': isDarkSkin },
                  { 'border-danger': errors[0] },
                ]"
                :format="'MM/dd/yyyy'"
              />
            </div>
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="type" rules="required">
          <div class="flex-row">
            <h6 class="pt-1">IDENTIFICATION TYPE</h6>
            <b-form-radio-group
              v-model="client.ssnType"
              :class="[
                'padding-radio widthInput1 d-flex justify-content-around',
                { 'border-danger': errors[0] },
                { borderDark: isDarkSkin },
                { borderLight: !isDarkSkin },
                { borderDanger: !client.validateType },
              ]"
              :options="ssnOptions"
              name="radios-stacked"
              @input="changeType()"
            />
          </div>
        </validation-provider>
        <!-- Input  type SSN -->

        <validation-provider
          v-slot="{ errors }"
          name="type"
          rules="required|min:4"
        >
          <transition name="fade">
            <div v-if="client.ssnType" class="flex-row">
              <h6 class="mt-1">
                LAST 4 DIGITS {{ ssnOptions[client.ssnType - 1].text }}
              </h6>
              <b-form-input
                v-model="client.ssnNum"
                v-mask="'####'"
                :class="['widthInput2', { 'border-danger': errors[0] }]"
              />
            </div>
          </transition>
        </validation-provider>
      </validation-observer>
    </b-container>
    <template #modal-footer>
      <div class="d-flex justify-content-center">
        <b-button variant="success" @click="toAccess" @keyup.enter="toAccess"
          >Send</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CallRoundService from "@/views/ce-digital/sub-modules/customer-service/views/call-round/service/callround.service";
import SearchDiditalCE from "../services/search-digital";

export default {
  components: {},
  props: {
    clientData: {
      type: Object,
    },
    motiveType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      infData: [],
      client: {
        dob: null,
        ssnType: null,
        ssnNum: null,
        validateType: true,
      },
      ssnOptions: [
        { value: 1, text: "SSN", disabled: false },
        { value: 2, text: "ITIN", disabled: false },
        { value: 3, text: "CPN", disabled: false },
      ],
      existentDocuments: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async mounted() {
    this.toggleModal("validate-access-modal");
  },
  methods: {
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    changeType() {
      this.$set(this.client, "validateType", true);
    },
    async getExistentDocuments() {
      const { data, status } = await SearchDiditalCE.getExistentDocuments({
        client_id: this.clientData.client_id,
      });
      if (status === 200) {
        this.existentDocuments = { ...data[0] };
        this.ssnOptions[0].disabled = this.existentDocuments.has_ssn === 0;
        this.ssnOptions[1].disabled = this.existentDocuments.has_itin === 0;
        this.ssnOptions[2].disabled = this.existentDocuments.has_cpn === 0;
      }
    },
    async toAccess() {
      if (this.client.ssnType) {
        const isValidated = await this.$refs.formValiateAccess.validate();
        if (isValidated) {
          try {
            const params = {
              client_id: this.clientData.client_id,
              dob: this.client.dob,
              type: this.client.ssnType,
              number: this.client.ssnNum,
            };

            const { data, status } =
              await SearchDiditalCE.validateAccessDashboard(params);
            if (status == 200) {
              if (data[0].cc) {
                this.addPreloader();
                // Change the state has completed in calls rounds for the reason type Welcome
                await this.setAvailableCallRounds();
                if (this.currentUser.role_id == 15) {
                  await this.changeToSessionActive(); // change status in call
                }

                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Successful login"
                );
                this.removePreloader();
                if (this.isAgent) {
                  await document.documentElement.requestFullscreen();
                }
                this.$router.push({
                  name: "ce-customer-service-dashboard",
                  params: {
                    idClient: this.clientData.client_account_id,
                    hasAccess: this.clientData.hasAccess,
                    typeAccess: this.clientData.typeAccess,
                    wayAccess: this.clientData.wayAccess,
                  },
                });
              } else {
                this.showWarningSwal("IMPORTANT!", "Incorrect customer data");
                this.removePreloader();
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        await this.$refs.formValiateAccess.validate();
        this.$set(this.client, "validateType", false);
      }
    },
    async setAvailableCallRounds() {
      try {
        const params = {
          client_account_id: this.clientData.client_account_id,
          user_id: this.currentUser.user_id,
          state: 3, // Available
          motive_type: this.motiveType,
          call_round_id: this.clientData.call_round_id,
        };
        await CallRoundService.setAvailableCallRoundCE(params);
      } catch (error) {
        console.log(error);
      }
    },
    async changeToSessionActive() {
      try {
        const params = {
          created_by: this.currentUser.user_id,
          sub_module_id: this.moduleId,
          status_sub_module_id: 5, // IN CALL
        };
        const data = await this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(params);
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    async clientData() {
      await this.getExistentDocuments();
    },
  },
};
</script>
<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0px 4px 0px;
}
.padding-radio {
  padding: 10px 0px 10px 12px;
  border-radius: 5px;
}
.widthInput1 {
  width: 60%;
}
.widthInput2 {
  width: 60%;
}
.borderDark {
  border: 1px solid #515153;
}
.borderLight {
  border: 1px solid #e6e5ea;
}
.borderDanger {
  border: 1px solid #e23f47;
}
</style>
