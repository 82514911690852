<template>
  <div>
    <b-modal
      v-model="control"
      title-class="h3 text-white"
      centered
      size="lg"
      :title="`Digital Migration Request`"
      modal-class="modal-primary"
      @hidden="close"
    >
      <ValidationObserver ref="form">
        <b-row>
          <b-col cols="6">
            <b-input-group size="sm" class="mb-1">
              <template #prepend>
                <b-input-group-text variant="gradient-primary"
                  >ACCOUNT</b-input-group-text
                >
              </template>
              <b-form-input
                style="'border-color:rgba(255, 255, 255, 0.4)'; "
                :style="
                  isDarkSkin
                    ? 'background: #17171A;opacity: 0.7;color:white'
                    : 'background: #efefef'
                "
                v-model="currentClient.account"
                disabled="disabled"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <b-input-group size="sm" class="mb-1">
              <template #prepend>
                <b-input-group-text variant="gradient-primary"
                  >CLIENT</b-input-group-text
                >
              </template>
              <b-form-input
                style="'border-color:rgba(255, 255, 255, 0.4)';"
                :style="
                  isDarkSkin
                    ? 'background: #17171A;opacity: 0.7;color:white'
                    : 'background: #efefef'
                "
                v-model="currentClient.client_name"
                disabled="disabled"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col cols="6" v-if="isRespondingMigrationRequestFromCeRegular">
            <b-input-group size="sm" class="mb-1">
              <template #prepend>
                <b-input-group-text variant="gradient-primary"
                  >ADVISOR</b-input-group-text
                >
              </template>
              <b-form-select
                id="advisor"
                v-model="advisorSelected"
                :options="advisors"
                text-field="name"
                value-field="id"
                :style="isAdvisorRequired ? 'border: 1px solid #fc424a' : ''"
              ></b-form-select>
            </b-input-group>
          </b-col>
          <b-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="Observation"
              rules="required"
            >
              <b-form-group label="Observation" label-for="Observation">
                <b-form-textarea
                  id="Observation"
                  :state="errors[0] ? false : null"
                  v-model="observation"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <div v-if="isMigrationRequest">
          <b-button
            class="mr-1"
            variant="success"
            @click="respondMigrationRequest(1)"
            >MIGRATE</b-button
          >
          <b-button :disabled="loading" variant="danger" @click="close()"
            >CANCEL</b-button
          >
        </div>
        <div v-else>
          <b-button
            class="mr-1"
            variant="success"
            @click="respondMigrationRequest(2)"
            >ACCEPT</b-button
          >
          <b-button
            :disabled="loading"
            variant="danger"
            @click="respondMigrationRequest(3)"
            >DECLINE</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import MigrationsService from "@/views/ce-digital/sub-modules/settings/views/migrations/services/migrations.service";
import UsersService from "@/views/ce-digital/sub-modules/settings/views/users/service/users.service.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    currentClient: {
      type: Object,
      required: true,
    },
    newClientTypeId: {
      type: Number,
      required: true,
    },
    isMigrationRequest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAdvisorRequired: false,
      advisors: [],
      advisorSelected: null,
      control: false,
      observation: "",
      loading: false,
    };
  },
  async created() {
    this.control = true;
  },
  async mounted() {
    if (!this.isMigrationRequest && this.currentModuleId === 6) {
      await this.getAllAdvisors();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      taskCounter: "SidebarStore/G_SIDEBAR_ITEMS",
      ceRegularMigrationsPending:
        "CreditExpertsMigrationsStore/G_COUNTER_MIGRATIONS_PENDING",
      ceDigitalMigrationsPending:
        "CeDigitalMigrations/G_COUNTER_MIGRATIONS_PENDING",
    }),
    isMigratingaDigitalClient() {
      return this.isMigrationRequest && this.newClientTypeId === 1;
    },
    currentModuleId() {
      return this.moduleId;
    },
    isRespondingMigrationRequestFromCeRegular() {
      return !this.isMigrationRequest && this.currentModuleId === 6;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async respondMigrationRequest(actionType) {
      // 1: Pending | 2: Accept | 3: Decline
      if (
        this.isRespondingMigrationRequestFromCeRegular &&
        actionType == 2 &&
        !this.advisorSelected
      ) {
        this.isAdvisorRequired = true;
        return;
      }
      this.isAdvisorRequired = false;
      const validation = await this.$refs.form.validate();
      if (!validation) return;
      const result = await this.showConfirmSwal();
      if (!result.isConfirmed) return;
      try {
        this.loading = true;
        this.addPreloader();
        await MigrationsService.MigrationClientTypeRequest({
          client_account_id: this.currentClient.id,
          observation: this.observation,
          migration_option: actionType,
          user_id: this.currentUser.user_id,
          new_client_type_id: this.newClientTypeId, // (1)Regular (2)Digital
          new_advisor_id: this.advisorSelected,
        });
        this.removePreloader();
        this.$emit("close");
        this.$emit("update");
        this.loading = false;

        if (actionType !== 1) {
          if (this.currentModuleId === 6) {
            // Update counters when the actions is being executed from Credit Experts Regular
            const payload = {
              routeName: "credit-experts-migrations",
              tag:
                this.ceRegularMigrationsPending < 2
                  ? 0
                  : this.ceRegularMigrationsPending - 1,
            };
            this.setCeMigrationPendingSidebar(payload);
            this.setCeRegularMigrationPendingTab({
              pending:
                this.ceRegularMigrationsPending < 2
                  ? 0
                  : this.ceRegularMigrationsPending - 1,
            });
          } else {
            // Update counters when the actions is being executed from Credit Experts Digital
            const payload = {
              routeName: "cdigital-migrations",
              tag:
                this.ceDigitalMigrationsPending < 2
                  ? 0
                  : this.ceDigitalMigrationsPending - 1,
            };
            this.setCeMigrationPendingSidebar(payload);
            this.setCeDigitalMigrationPendingTab({
              pending:
                this.ceDigitalMigrationsPending < 2
                  ? 0
                  : this.ceDigitalMigrationsPending - 1,
            });
          }
        }
        this.showSuccessSwal();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
        this.loading = false;
      }
    },
    close() {
      this.$emit("close");
      this.control = false;
    },
    async getAllAdvisors(module = 6) {
      const response = await UsersService.getAllAdvisorForCreditExpert({
        module_id: module,
      });
      this.advisors = response.sort(function (userA, userB) {
        // Order advisors alphabetically
        if (userA.name < userB.name) {
          return -1;
        }
        if (userA.name > userB.name) {
          return 1;
        }
        return 0;
      });
    },
    ...mapActions({
      setCeRegularMigrationPendingTab:
        "CreditExpertsMigrationsStore/A_SET_COUNTERS_TABS_MIGRATIONS",
      setCeDigitalMigrationPendingTab:
        "CeDigitalMigrations/A_SET_COUNTERS_TABS_MIGRATIONS",
    }),
    ...mapMutations({
      setCeMigrationPendingSidebar: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
  },
};
</script>

<style>
</style>currentModuleId === 6