import { amgApi } from "@/service/axios"

class DigitalClients {
  async getTrackingMigrationClients(params) {
    try {
      const data = await amgApi.post(
        "/clients/tracking-clients-migration",
        params
      )
      return data
    } catch (err) {
      console.error("Error in getTrackingMigrationClients", err)
    }
  }
  async getCountAlertsToPurge() {
    try {
      const data = await amgApi.get(
        "/credit-experts-digital/alert/get-count-alerts-to-purge"
      )
      return data
    } catch (err) {
      console.error("Error in getCountAlertsToPurge", err)
    }
  }
  async deleteAlertsToPurge() {
    try {
      const data = await amgApi.post(
        "/credit-experts-digital/alert/delete-alerts-to-purge"
      )
      return data
    } catch (err) {
      console.error("Error in deleteAlertsToPurge", err)
    }
  }
  async getSearchClients(params) {
    try {
      const {data} = await amgApi.post(
        "/clients/ce-digital-search-clients",
        params
      );
      return data
    } catch (err) {
      console.error("Error in deleteAlertsToPurge", err)
    }
  }
}

export default new DigitalClients()
