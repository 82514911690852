<template>
  <b-modal
    ref="modalCustomerTracking"
    size="sm"
    modal-class="custom-modal-amg"
    hide-footer
    hide-header-close
    @hidden="close"
    header-bg-variant="transparent"
    header-class="p-0"
    centered
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <span>Set Date for Customer Tracking</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <b-row class="justify-content-center px-1">
      <form-validate-hour
        class="w-100"
        :alert="false"
        :show-hour="false"
        @submitSave="setCustomerTracking"
      ></form-validate-hour>
    </b-row>
  </b-modal>
</template>

<script>
import moment from "moment";
import CustomerService from "@/views/ce-digital/sub-modules/customer-service/views/clients/service/clients.service.js";
import FormValidateHour from "@/views/ce-digital/sub-modules/customer-service/views/commons/FormValidateHour.vue";
export default {
  components: {
    FormValidateHour,
  },
  props: {
    idClient: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalCustomerTracking: false,
      date_tracking: "",
    };
  },
  mounted() {
    this.toggleModal("modalCustomerTracking");
  },
  methods: {
    async setCustomerTracking(date) {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            account_id: this.idClient,
            date_tracking: moment(date.date_event).format("YYYY-MM-DD"),
          };
          const data = await CustomerService.insertCustomerTracking(params);
          if (data.status === 200) {
            this.showSuccessSwal();
            this.$emit("close", true);
          }
        } catch (error) {
          this.showErrorSwal(
            "Confirm if the client has an assigned time or if the client has a mobile number, otherwise contact support"
          );
        }
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
