<template>
  <b-modal
    ref="modal-waiting-alerts"
    modal-class="modal-primary"
    title="Waiting Alerts"
    title-tag="h3"
    size="xlg"
    hide-footer
    @hidden="close"
  >
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        v-for="tab in tabs"
        :key="tab.id"
        exact
        :link-classes="['px-3', bgTabsNavs]"
        :active="tab.id === selectedTab"
        @click="changeTab(tab)"
      >{{ tab.name }}</b-nav-item>
    </b-nav>
    <b-card
      :key="selectedTab"
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refAlertWaiting'].refresh()"
      >
        <template #table>
          <b-table
            ref="refAlertWaiting"
            no-border-collapse
            class="position-relative"
            :fields="fieldAlerts"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="trackingProvider"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :filter="searchInput"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(created_at)="data">
              <div class="text-center">
                <span>{{ data.item.created_at | myGlobalWithHour }}</span>
              </div>
            </template>
            <template #cell(sent_to_call_round)="data">
              <div class="d-flex justify-content-center">
                <b-badge
                  v-if="data.item.sent_to_call_round === 1"
                  variant="warning"
                  class="w-100"
                >
                  <span>YES</span>
                </b-badge>
              </div>
            </template>
            <template #cell(lead_name)="data">
              <div>
                <div class="d-flex flex-row align-items-center">
                  <p
                    class="p-0 m-0"
                    style="padding-left: 4px !important"
                    :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                  >
                    {{ data.item.lead_name }}
                  </p>
                  <feather-icon
                    v-if="data.item.priority === 1"
                    icon="StarIcon"
                    class="ml-1"
                    style="color: #ffc107; fill: #ffc107"
                  />
                </div>
                <status-account :account="data.item" />
                <div>
                  <p class="m-0">
                    {{ data.item.account }}
                  </p>
                </div>
              </div>
            </template>
            <template #cell(date_event)="data">
              <div class="text-center">
                <span>{{ data.item.date_event | myGlobalWithHour }}</span>
                <br>
                <span class="badge badge-pill badge-dark">
                  {{ data.item.date_order == 1 ? "ORIGINAL" : "STRAGGLER" }}
                </span>
              </div>
            </template>
            <template #cell(alert_type)="data">
              <b-badge
                :variant="`light-${typeMotive(data.item.alert_type)}`"
                class="w-100"
              >
                {{ data.item.alert_type_name }}
              </b-badge>
            </template>

            <template #cell(alert_status)="data">
              <b-badge
                :variant="
                  getVariantColor(data.item.alert_status, data.item.deny_first)
                "
                class="w-100"
              >
                <span>{{ data.item.alert_status }}</span>
                <div
                  v-if="
                    data.item.alert_time_released &&
                      [2, 3].includes(data.item.alert_status_id)
                  "
                  style="margin-top: 2px"
                >
                  {{ data.item.alert_time_released | myGlobalWithHour }}
                </div>
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <div class="text-center">
                <feather-icon
                  v-if="data.item.priority === 0"
                  icon="StarIcon"
                  class="mr-2 text-warning text-center clickable"
                  @click="setAlertToQueue(data.item)"
                />
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>
  </b-modal>
</template>

<script>
import moment from 'moment';
import AlertWaitingFilters from '@/views/ce-digital/sub-modules/customer-service/views/clients-table/components/modals/alerts-pending/alert-waiting.filters';
import AlertTrackingData from '@/views/ce-digital/sub-modules/customer-service/views/clients-table/components/modals/alerts-pending/alert-waiting.data';
import AlertTrackingService from '@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/service/alert-tracking.service';
import CedDashboardTracking from '@/views/ce-digital/sub-modules/customer-service/views/dashboard-tracking/service/ced-dashboard-tracking';
import AlertActionService from '@/views/ce-digital/sub-modules/customer-service/views/auto-alerts/services';
import StatusAccount from '@/views/commons/components/clients/stylescomponents/StatusAccount.vue';

export default {
  name: 'DashboardTracking',
  components: {
    StatusAccount,
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: 'PENDING',
        },
        {
          id: 2,
          name: 'OVERDUE',
        },
      ],
      selectedTab: 1,
      filters: AlertWaitingFilters,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by agent name...',
        model: '',
      },
      fields: AlertTrackingData,
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      searchInput: '',
      mood: '',
      items: [],
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    fieldAlerts() {
      this.fields[7].visible = this.selectedTab === 2;
      return this.fields.filter(field => field.visible);
    },
  },
  async created() {
    await this.setUpFiltersData();
  },
  mounted() {
    this.toggleModal('modal-waiting-alerts');
    window.socket.unsubscribe('channel-chat');
    window.socket.subscribe('channel-chat');
    window.socket.bind('alert-customer-service', data => {
      this.changeAlertToInProcess(data);
    });
    window.socket.bind('alert-state-changed', data => {
      this.changeAlertState(data);
    });
  },
  methods: {
    changeTab(tab) {
      this.selectedTab = tab.id;
    },
    async trackingProvider() {
      const params = {
        text: this.filterPrincipal.model,
        date_from: this.filters[0].model,
        date_to: this.filters[1].model,
        per_page: this.paginate.perPage,
        page: this.paginate.currentPage,
        agent_id: this.filters[2].model,
        status: this.filters[3].model,
        orderby: 'created_at',
        order: 'desc',
        type: this.selectedTab,
        language: this.filters[4].model ? this.filters[4].model : '',
      };
      const data = await AlertTrackingService.searchWaitingAlerts(params);
      this.items = data.data.data;
      this.startPage = data.data.from;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = data.data.last_page;
      this.totalRows = data.data.total;
      this.toPage = data.data.to;
      return this.items || [];
    },
    async setUpFiltersData() {
      const dataAgents = await CedDashboardTracking.getSubModuleAgents({
        sub_module_id: this.moduleId,
      });
      if (dataAgents.status === 200) {
        this.filters[2].options = dataAgents.data;
      }
      this.filters[3].options = [
        {
          id: 1,
          label: 'PENDING',
        },
        {
          id: 2,
          label: 'IN PROCESS',
        },
        {
          id: 3,
          label: 'IN ACTIONS',
        },
      ];
    },
    getVariantColor(alert_status, deny) {
      switch (alert_status) {
        case 'PENDING':
          return 'secondary';
        case 'IN PROCESS':
          return deny === 1 ? 'danger' : 'primary';
        case 'DONE':
          return 'success';
        default:
          return 'warning';
      }
    },
    changeAlertToInProcess(data) {
      data.alerts.map(item => {
        this.items.map(item2 => {
          if (item2.alert_id === item.alert.id) {
            item2.alert_status = 'IN PROCESS';
            item2.alert_status_id = 2;
            item2.alert_time_released = moment().format('YYYY-MM-DD HH:mm:ss');
            item2.deny_first = 0;
          }
        });
      });
    },
    changeAlertState(data) {
      this.items.map(item => {
        if (item.alert_id === data.alert.alert_id) {
          item.deny_first = data.alert.deny_first ? 1 : 0;
          if (data.alert.alert_status && data.alert.alert_status_id == 3) {
            item.alert_status = data.alert.alert_status;
            item.alert_status_id = data.alert.alert_status_id;
            item.alert_time_released = moment().format('YYYY-MM-DD HH:mm:ss');
          }
          if (data.alert.drop_alert == 1) {
            this.$refs.refAlertWaiting?.refresh();
          }
        }
      });
    },
    async setAlertToQueue(alert) {
      const params = {
        alert_id: alert.alert_id,
      };
      try {
        await AlertActionService.setAlertToQueue(params);
        this.$refs.refAlertWaiting?.refresh();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    typeMotive(type) {
      switch (type) {
        case 1:
          return 'primary';
        case 2:
          return 'secondary';
        case 3:
          return 'success';
        case 4:
          return 'warning';
        case 5:
          return 'danger';
        case 6:
          return 'info';
        case 7:
          return 'dark';
        case 8:
          return 'primary';
        case 9:
          return 'secondary';
        case 10:
          return 'success';
        case 11:
          return 'warning';
        default:
          return 'info';
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.table tr.table-added-row {
  animation-name: addedrow;
  animation-duration: 2s;
}

.table tr.table-added-row td {
  animation-name: addedrowcell;
  animation-duration: 2s;
}

.table tr.table-added-row td > div {
  animation-name: addedrowcelldiv;
  animation-duration: 2s;
}

@keyframes addedrow {
  50% {
    background-color: #c3e6cb;
  }
  75% {
    background-color: #c3e6cb;
  }
}

@keyframes addedrowcell {
  0% {
    padding: 0 0.75rem;
  }
  50% {
    padding: 0.75rem;
  }
}

@keyframes addedrowcelldiv {
  0% {
    transform: scale(1, 0);
  }
  50% {
    transform: scale(1, 1);
  }
}
</style>
