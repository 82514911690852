export default [
  {
    key: "selected",
    label: "",
    class: "text-left",
    visible: true,
  },
  {
    key: "lead_name",
    label: "Name",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "account",
    label: "Account",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "qty_services",
    label: "Others",
    sortable: false,
    visible: true,
    class: "text-center",
  },
  {
    key: "task",
    label: "Task",
    class: "text-center",
    sortable: true,
    visible: true,
  },
  {
    key: "date_activation",
    label: "APP",
    class: "text-center",
    sortable: true,
    visible: true,
  },
  {
    key: "mobile",
    label: "Phone number",
    class: "text-center",
    visible: true,
  },
  {
    key: "state_lp",
    label: "Paid",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "monthly_amount",
    label: "MP",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "last_payment",
    label: "LP",
    class: "text-left ",
    sortable: true,
    visible: true,
  },
  {
    key: "status_payment",
    label: "PT",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "score",
    label: "Score",
    class: "text-left",
    tdClass: "px-2",
    visible: true,
  },
  {
    key: "ln_date",
    label: "LN",
    class: "text-center",
    tdClass: "center",
    sortable: true,
    visible: true,
  },
  {
    key: "access_cred",
    label: "Access Credential",
    class: "text-left",
    visible: false,
  },
  {
    key: "enrol_date",
    label: "Enrol Date",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "migration",
    label: "Migration Status",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "service",
    label: "Services",
    class: "text-left",
    visible: false,
  },
  {
    key: "actions",
    label: "Actions",
    class: "text-left",
    visible: true,
  },
  {
    key: "tracking",
    label: "Migration Tracking",
    class: "text-center",
    visible: true,
  },
];
