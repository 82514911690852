export default [    
    {
        key: "program",
        label: "Service"
    },
    {
        key: "account",
        label: "Account"
    },
    {
        key: "status_client",
        label: "Status"
    },
    {
        key: "enroll_date",
        label: "Enrolled at"
    },    
]